<template>
  <section class="navbar">
    <navbar :white-background="false"></navbar>
  </section>
<xmas-comp></xmas-comp>
</template>

<script>
import XmasComp from "@/components/xmasTree/xmasComp";
import Navbar from "@/components/navbar";
export default {
  name: "xmasPage",
  components: {Navbar, XmasComp}
}
</script>

<style scoped>

</style>
