<template>
  <div class="container">
    <div class="level">
      <span>Level: {{ level + 1 }}</span>
      <br>
      <br>
      <span :class="{'bigger': !playerClass}">Last Result: {{ player_1 }}</span>
      <br>
      <br>
      <span>Record: {{record}}</span>

    </div>
    <button type="button" v-on:click="genSeq">{{ buttonText }}</button>

    <div class="container-tree" :style="myCursor">

      <div id="top" v-on:click="registerClick(1)"></div>
      <div id="middle" v-on:click="registerClick(2)"></div>
      <div id="bottom" v-on:click="registerClick(3)"></div>
      <div id="trunk" v-on:click="registerClick(4)"></div>
    </div>
    <teleport to="#modals">
      <div v-if="looser || winner" class="modal">
        <div>
          <button type="button" v-on:click="restart">Continue</button>
          <span class="result">{{ gameResult }}</span>
          <span class="result">Level: {{ level + 1 }}</span>
        </div>
      </div>
    </teleport>
  </div>

</template>

<script>


export default {
  name: "xmasComp",
  watch: {
    ready() {
      if (this.ready) {
        this.myCursor = {cursor: 'pointer'}
      } else {
        this.myCursor = null
      }
    },

  },
  computed: {
    playerClass() {
      return this.playerOn !== 'player_1';
    },
    level() {
      return this.$store.state.level
    },
    record() {
      return this.$store.state.record
    },
    buttonText() {
      if (this.ready) {
        return 'Restart'
      } else {
        return 'Start'
      }
    },
    gameResult() {
      if (this.winner) {
        return 'You won '.toUpperCase() + ':)'
      } else {
        return 'You lost '.toUpperCase() + ':('
      }
    }
  },
  data() {
    return {
      randomArr: [],
      resultArr: [],
      maxSeqLength: 4,
      seqLength: 0,
      seqSpeed: 1000,
      ready: false,
      myCursor: null,
      winner: false,
      looser: false,
      playerOn: 'player_1',
      player_1: 0,
      player_2: 0,
      playerStyle: {fontWeight: 'bolder'}
    }
  },
  methods: {
    restart() {
      if (this.winner) {
        setTimeout(() => {
          this.genSeq()
        }, 1000)
      } else {
        this.looser = false
      }

    },
    setDefaults() {
      this.winner = false
      this.looser = false
      this.ready = false
      this.seqLength = this.$store.state.level
      this.resultArr = []
      this.randomArr = []
    },
    async genSeq() {

      this.setDefaults()

      this.seqLength = this.seqLength + 2

      const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

      for (let i = 0; i < this.seqLength; i++) {
        const flashNum = Math.ceil(Math.random() * 4)
        this.randomArr.push(flashNum)
        this.startSeq(flashNum)
        await wait(this.seqSpeed)
      }
      this.ready = true
    },
    startSeq(num) {
      const el_1 = document.querySelector('#top')
      const el_2 = document.querySelector('#middle')
      const el_3 = document.querySelector('#bottom')
      const el_4 = document.querySelector('#trunk')
      setTimeout(() => {
        if (num === 1) {
          this.flash(el_1)
        } else if (num === 2) {
          this.flash(el_2)
        } else if (num === 3) {
          this.flash(el_3)
        } else if (num === 4) {
          this.flash(el_4)
        }

      }, 1000)

    },
    flash(el) {

      const timings = {
        duration: 200
      }
      el.animate([
        {},
        {backgroundColor: 'white', transform: 'scale(1.03)'}
      ], timings)
    },
    registerClick(num) {
      if (!this.ready) return

      const el_1 = document.querySelector('#top')
      const el_2 = document.querySelector('#middle')
      const el_3 = document.querySelector('#bottom')
      const el_4 = document.querySelector('#trunk')

      if (num === 1) {
        this.flash(el_1)
      } else if (num === 2) {
        this.flash(el_2)
      } else if (num === 3) {
        this.flash(el_3)
      } else if (num === 4) {
        this.flash(el_4)
      }

      this.resultArr.push(num)
      if (this.resultArr.length === this.randomArr.length) {
        this.ready = false
        setTimeout(() => {
          this.evaluateResult()
        }, 500)
      }
    },
    evaluateResult() {
      if (this.randomArr.toString() === this.resultArr.toString()) {
        this.winner = true
        this.$store.commit('increase')
      } else {
        if (this.playerOn === 'player_1') {
          this.player_1 = this.level + 1
        }
        if (this.playerOn === 'player_2') {
          this.player_2 = this.level + 1
        }
        this.looser = true
        if (this.level > this.record) {
          this.$store.commit("setRecord", this.level)
        }
        this.$store.commit('reset')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  background-color: #2c3e50;
  width: 100%;
  height: 100vh;
  position: relative;

  button {
    position: absolute;
    right: 6%;
    top: 4%;
    padding: 3rem;
    background-color: darkred;
    color: whitesmoke;
    font-size: large;
    border-radius: 1rem;
    box-shadow: var(--shadow-small);
    z-index: 2;
    @media screen and (min-width: 40em) {
      right: 16%;
      top: 16%;
    }
  }
}

.container-tree {
  padding-top: 5vh;

}

.level {
  position: absolute;
  left: 6%;
  top: 8%;
  z-index: 2;
  color: white;
  font-size: 2rem;
  @media screen and (min-width: 40em) {
    right: 16%;
    top: 16%;
    font-size: 3rem;

  }
}


#top {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  background-color: #2d8f51;
  width: 15rem;
  height: 15rem;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

#middle {
  position: relative;
  z-index: 1;
  bottom: 5rem;
  margin: 0 auto;
  background-color: #2d8f51;
  width: 30rem;
  height: 25rem;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

#bottom {
  position: relative;
  bottom: 10rem;
  margin: 0 auto;
  background-color: #2d8f51;
  width: 40rem;
  height: 25rem;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

#trunk {
  margin: 0 auto;
  height: 9rem;
  width: 7rem;
  position: relative;
  background-color: saddlebrown;
  bottom: 10rem;
}

.bigger {
  font-weight: bolder;
}
</style>
